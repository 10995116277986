<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Asosiy Vosita Hujjat malumotlari</h3>
          </div>
        </div>
        <div class="card-body">
          <!-- {{mainAssetsDocDetails}} -->
          <!-- {{ddd}} -->
          <div>
            <div class="d-flex flex-wrap">
              <div class="col-3">
                <v-text-field
                  label="ID"
                  outlined
                  dense
                  disabled
                  v-model="mainAssetsDocDetails.id"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  label="Kontragent nomi"
                  disabled
                  v-model="mainAssetsDocDetails.contragent_name"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  label="Kontragent kontrakt raqami"
                  disabled
                  v-model="mainAssetsDocDetails.contragent_contract_number"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Sana"
                  v-model="mainAssetsDocDetails.oper_date"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Reg raqami"
                  v-model="mainAssetsDocDetails.reg_number"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  label="Izoh"
                  disabled
                  v-model="mainAssetsDocDetails.comment"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  label="Manba"
                  disabled
                  v-model="mainAssetsDocDetails.payment_source_name"
                >
                </v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  label="Mas'ul"
                  disabled
                  v-model="mainAssetsDocDetails.responsible_name"
                >
                </v-text-field>
              </div>
            </div>
          </div>
          <v-data-table
            :headers="headersdoc"
            :items="mainAssetsDocDetails.details"
            hide-default-footer
            class="elevation-1"
          >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      expanded: [],
      singleExpand: true,
      testtable: {},
      measurement2: '',
      name: '',
      headers: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Kontragent nomi',
          value: 'contragent_name'
        },
        {
          text: 'Kontragent kontrakt raqami',
          value: 'contragent_contract_number'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'Reg raqam',
          value: 'reg_number'
        },
        {
          text: 'Izoh',
          value: 'comment'
        },
        {
          text: 'Manba',
          value: 'payment_source'
        },
        {
          text: 'Kontragent',
          value: 'contragent'
        },
        {
          text: 'Kontragent kontrakt',
          value: 'contragent_contract'
        },
        {
          text: 'Responsible',
          value: 'responsible'
        },
        { text: '', value: 'data-table-expand' }
      ],
      headersdoc: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'Nomenklatura nomi',
          value: 'import_category.name'
        },
        {
          text: 'Holati',
          value: 'product_condition.name'
        },
        {
          text: 'Soni',
          value: 'amount'
        },
        {
          text: 'Narxi',
          value: 'price'
        },
        {
          text: 'Jami',
          value: 'total'
        },
        {
          text: 'nds',
          value: 'nds'
        },
        {
          text: 'nds_per_item',
          value: 'nds_per_item'
        },
        {
          text: 'Nds summa',
          value: 'nds_summa'
        },
        {
          text: 'jami nds summa',
          value: 'total_withnds_summa'
        }
      ]
    }
  },
  beforeCreate() {
    this.$store.dispatch('mainAssets')
    this.$store.dispatch('getMeasurements')
    this.$store.dispatch('getstafflistall')
    this.$store.dispatch('getPaymentSource')
  },
  created() {
    const data = this.mainAssetsDocDetails.details
    data.forEach(
      (element) => (this.measurement2 = element.import_category.measurement)
    )
  },
  computed: {
    ddd() {
      return this.$store.getters.test
    },
    mainAssetsDoc() {
      return this.$store.state.requests.mainAssets.results
    },
    getData() {
      return this.$store.state.requests.measurements
    },
    mainAssetsDocDetails2() {
      return this.$store.state.requests.mainAssets.results
    },
    mainAssetsDocDetails() {
      return this.$store.state.requests.mainAssets.results.find(
        (obj) => obj.id == this.$route.params.id
      )
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: ' Moliya' },
      { title: 'Asosiy Vositalar Hujjati' }
    ])
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
